import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import Airtable from 'airtable';
import { AirtableBase } from 'airtable/lib/airtable_base';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  public formGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    location: new FormControl('',
      Validators.required
    )
  });

  private API_KEY = "keyyv4qCjMa1U7aSX";
  private TABLE_ID = 'appVq1YWiesyAb4LY';
  private base: AirtableBase;

  constructor(private dialogRef: MatDialogRef<DialogComponent>) {
    this.dialogRef.disableClose = true;
    Airtable.configure({ apiKey: this.API_KEY});
    this.base = Airtable.base(this.TABLE_ID);
  }

  ngOnInit() {
  }

  validate() {
    if (this.formGroup.valid) {
      this.base('contacts').create([
        {
          "fields": {
            "email": this.formGroup.controls.email.value,
            "Port d'attache": this.formGroup.controls.location.value,
          }
        }
      ], function(err, records) {
        if (err) {
          console.error(err);
          return;
        }
        records.forEach(function (record) {
          console.log(record.getId());
        });
        this.dialogRef.close();
      });
    }
  }

}
