import { DialogComponent } from './../dialog/dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {

  public boats = [
    {
      name: "Beneteau Flyer 9 Spacedeck",
      type: "8,1 m - Bateau à moteur",
      icon: "directions_boat",
      price: "22 000",
      location: "Port Leucate",
      size: "2CAB - 9PS",
      image: "/assets/flyer_9_gp_4.jpeg"
    },
    {
      name: "Oceanis Yacht 62",
      type: "19,1 m - Beneteau",
      icon: "sailing",
      price: "215 000",
      location: "Ajaccio",
      size: "4CAB - 8PS",
      image: "/assets/ob_aa0ccd_gc-oceanis-yacht-62-2016-19.jpeg"
    },
    {
      name: "Bavaria 40 Vision",
      type: "12,7 m - Beneteau",
      icon: "sailing",
      price: "29 000",
      location: "Agde",
      size: "3CAB - 8PS",
      image: "/assets/bavaria-40-vision-651_max.jpeg"
    },
    {
      name: "Dufour 375 Grand Large",
      type: "11,4 m - Modèle Camargue",
      icon: "sailing",
      price: "25 500",
      location: "Nice Alpes Maritimes",
      size: "4CAB - 8PS",
      image: "/assets/DUFOUR-375-NAVIGATION-scaled.jpeg"
    },
    {
      name: "Capelli Tempest 1000 CC",
      type: "9,6 m - Bateau à moteur",
      icon: "directions_boat",
      price: "34 200",
      location: "Le Golfe Juan",
      size: "1CAB - 10PS",
      image: "/assets/4828583_20140929022750834_1_XLARGE.jpeg"
    },
    {
      name: "Oceanis 331, Beneteau",
      type: "10,3 m - Sloops",
      icon: "sailing",
      price: "14 000",
      location: "Marseille",
      size: "4CAB - 8PS",
      image: "/assets/oceanis331.jpeg"
    },
    {
      name: "Lagoon 500",
      type: "15,5 m - catamaran",
      icon: "sailing",
      price: "85 000",
      location: "La Trinité-sur-mer",
      size: "5CAB - 14PS",
      image: "/assets/lagoon-500-huge-29530140784c730d.jpeg"
    },
    {
      name: "Oceanis Yacht 62",
      type: "19,1 m - Beneteau",
      icon: "sailing",
      price: "170 000",
      location: "La Baule",
      size: "4CAB - 8PS",
      image: "/assets/xl_out01.jpg.pagespeed.ic.2a2rj83POj.jpg"
    },
  ]

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
    this.openDialog();
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent);
  }

}
