import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  listItems = [
    {
      name: "Home",
      disabled: false,
      path: '/home'
    },
    {
      name: "Technology",
      disabled: false,
      path: '/technology'
    },
    {
      name: "Services",
      disabled: false,
      path: '/services'
    },
    {
      name: "Team",
      disabled: false,
      path: '/team'
    },
    {
      name: "Missions",
      disabled: false,
      path: '/missions'
    },
    {
      name: "News",
      disabled: false,
      path: '/news'
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
